import React, { memo } from 'react';
import { Typography } from '@mui/material';
import MonetizingLink from '@components/MonetizingLink';

interface ApprovedPromotionProps {
  extraDeal: string;
  extraDealLink: string;
  tag: string;
  isDarkMode: boolean;
}

const ApprovedPromotion = memo((props: ApprovedPromotionProps): JSX.Element => {
  const { extraDeal, extraDealLink, tag, isDarkMode } = props;
  return (
    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
      *Also eligible for promotion{' '}
      <MonetizingLink
        href={`${extraDealLink}?tag=${tag}`}
        sx={{
          fontSize: '14px',
          lineHeight: '22px',
          fontWeight: 700,
          textDecoration: 'none',
          transition: 'color .5s',
          color: isDarkMode ? '#bda4f4' : '#392e50',
          ':hover': {
            color: '#c9cf6a'
          }
        }}
        tag={tag}
        clickType="promotion-link"
        aria-label="View deal promotion at Amazon.com"
      >
        {extraDeal.toLowerCase()}
      </MonetizingLink>
      !*
    </Typography>
  );
});

export default ApprovedPromotion;
